var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.$route.meta.version === true
        ? _c("div", { attrs: { id: "version" } }, [_c("router-view")], 1)
        : _vm._e(),
      _vm._v(" "),
      _vm.$route.meta.ribbon != false ? _c("ribbon") : _vm._e(),
      _vm._v(" "),
      _vm.$route.meta.header != false
        ? _c("div", { attrs: { id: "app-header" } }, [_c("jhi-navbar")], 1)
        : _vm._e(),
      _vm._v(" "),
      _vm.$route.meta.container != false
        ? _c(
            "div",
            { staticClass: "container-fluid" },
            [
              _c(
                "div",
                { staticClass: "card jh-card" },
                [_c("router-view")],
                1
              ),
              _vm._v(" "),
              _vm.$route.meta.login != false
                ? _c(
                    "b-modal",
                    {
                      attrs: { id: "login-page", "hide-footer": "", lazy: "" },
                    },
                    [
                      _c(
                        "span",
                        {
                          attrs: {
                            slot: "modal-title",
                            "data-cy": "loginTitle",
                            id: "login-title",
                          },
                          domProps: {
                            textContent: _vm._s(_vm.$t("login.title")),
                          },
                          slot: "modal-title",
                        },
                        [_vm._v("Sign in")]
                      ),
                      _vm._v(" "),
                      _c("login-form"),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.$route.meta.footer != false ? _c("jhi-footer") : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }