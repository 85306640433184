import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// jhcc-custom begin
const InstanceComponent = () => import('../entities/instance/instance.vue');
const LoggersComponent = () => import('../entities/loggers/loggers.vue');
const MetricComponent = () => import('../entities/metric/metric.vue');
const HealthComponent = () => import('../entities/health/health.vue');
const LogfileComponent = () => import('../entities/logfile/logfile.vue');
const ConfigurationComponent = () => import('../entities/configuration/configuration.vue');
const DocsComponent = () => import('../entities/docs/docs.vue');
const CachesComponent = () => import('../entities/caches/caches.vue');
const LiquibaseComponent = () => import('../entities/liquibase/liquibase.vue');
// jhcc-custom end

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'instance',
      name: 'InstanceComponent',
      component: InstanceComponent,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'loggers',
      name: 'LoggersComponent',
      component: LoggersComponent,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'metric',
      name: 'MetricComponent',
      component: MetricComponent,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'health',
      name: 'HealthComponent',
      component: HealthComponent,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'logfile',
      name: 'LogfileComponent',
      component: LogfileComponent,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'configuration',
      name: 'InstanceConfigurationComponent',
      component: ConfigurationComponent,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'docs',
      name: 'DocsComponent',
      component: DocsComponent,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'caches',
      name: 'CachesComponent',
      component: CachesComponent,
      meta: { authorities: [Authority.ADMIN] },
    },
    {
      path: 'liquibase',
      name: 'LiquibaseComponent',
      component: LiquibaseComponent,
      meta: { authorities: [Authority.ADMIN] },
    },
  ],
};
